<template>
  <section class="container my-24 my-lg-32 evraz">
    <div class="row mb-24">
      <header class="col">
        <h2>{{ title }}</h2>
      </header>
    </div>

    <v-sheet
        outlined
        elevation="10"
        class="py-16 py-lg-24 pl-0 pl-lg-16 pr-16 pr-lg-24"
    >
      <div class="row">
        <div class="col-auto">
          <div class="evraz-mentor-avatar">
            <img
                :alt="mentor.name"
                :src="mentor.img"
                class="evraz-mentor-avatar__img"
            />
          </div>
        </div>
        <div class="col">
          <p
              v-html="mentor.name"
              class="h3 text-bold text-color-primary"
          />
        </div>
      </div>
    </v-sheet>

    <v-divider
        style="border-color: #999999"
        class="my-32"
    />

    <div class="row mb-24">
      <div class="col">
        <h4 class="h3">Наставляемые сотрудники ({{ employees.length }})</h4>
      </div>
    </div>

    <v-sheet
        v-for="employee of employees"
        :key="employee.id"
        outlined
        elevation="10"
        class="py-16 pl-0 pr-16 pl-lg-16 mb-16 mb-md-24"
    >
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header no-icon>
            <template v-slot:default="{ open }">
              <div class="row align-items-stretch">
                <div class="col-auto">
                  <div class="evraz-mentor-avatar">
                    <img
                        :alt="employee.name"
                        :src="employee.img"
                        class="evraz-mentor-avatar__img"
                    />
                  </div>
                </div>

                <div class="col">
                  <div class="row h-100 flex-column justify-content-between">
                    <div class="col-auto">
                      <div class="row align-items-center">
                        <div class="col">
                          <p
                              v-html="employee.name"
                              class="text-bold m-0"
                          />
                        </div>

                        <div class="col-auto">
                          <v-icon
                              name="evraz-chevron"
                              sprite-name="evraz"
                              fill="primary"
                              size="16"
                              :class="{'a-flip-y_active': !open}"
                              class="a-flip-y mr-4 d-md-none"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-auto">
                      <v-divider class="mt-8"/>
                    </div>

                    <div class="col d-none d-md-block">
                      <div class="row">
                        <div
                            v-for="(item, index) in employee.other"
                            :key="item.id"
                            :class="{'col-md-12 col-lg': index === 0}"
                            class="col mt-8"
                        >
                          <p class="mb-4 text-color-default text-size-14">{{ item.title }}</p>
                          <p class="mb-0 text-size-14" >{{ item.text }}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <div class="row d-md-none">
              <div
                  v-for="(item, index) in employee.other"
                  :key="item.id"
                  :class="{'col-lg-6': index !== 0}"
                  class="col-12 mt-16"
              >
                <div class="pl-16">
                  <p class="mb-4 text-color-default text-size-14">{{ item.title }}</p>
                  <p class="mb-0">{{ item.text }}</p>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-sheet>
  </section>
</template>

<script>
import {
  VDivider,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VIcon,
  VSheet
} from '@components/base'

export default {
  name: 'SEvrazLKMentor',

  components: {
    VIcon,
    VDivider,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VExpansionPanels,
    VSheet
  },

  data () {
    return {
      title: 'Личный кабинет для взаимодействия с наставляемыми в рамках программы ТОП-300 4 поток',
      mentor: {
        name: 'Сергиенко<br/>Сергей Сергеевич',
        img: require('@assets/images/verstka/evraz/mentor.jpg'),
        other: []
      },
      employees: [
        {
          id: 1,
          name: 'Ческидов<br/>Александр Александрович',
          img: require('@assets/images/verstka/evraz/user1.jpg'),
          other: [
            { id: 1, title: 'Функция:', text: 'Начальник отдела сопровождения проектов ЕВРАЗ НТМК' },
            { id: 2, title: 'Предприятие:', text: 'ЕВРАЗ НТМК' },
            { id: 3, title: 'Подразделение:', text: 'Дивизион Урал' }
          ]
        },
        {
          id: 2,
          name: 'Канонеров<br/>Павел Михайлович',
          img: require('@assets/images/verstka/evraz/user3.png'),
          other: [
            { id: 1, title: 'Функция:', text: 'Менеджер проекта' },
            { id: 2, title: 'Предприятие:', text: 'ЕВРАЗ НТМК' },
            { id: 3, title: 'Подразделение:', text: 'Дивизион Урал' }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.evraz {
 ul, ol {
   margin: 0;
 }
}

.evraz-mentor-avatar {
  position: relative;
  display: flex;
  padding-left: 1rem;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 1rem;
    height: 3rem;
    background: #fcb53b;
    background: linear-gradient(0deg, #fcb53b 33%, #f57f29 33%, #f57f29 66%, #e32213 66%);
  }

  &__img {
    display: block;
    width: 100%;
    height: auto;
    max-height: 6rem;
    max-width: 4rem;
    background-color: white;
  }
}
</style>
